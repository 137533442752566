.App{
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

.caret-blink{
    animation:blink-caret-animation .75s step-end infinite;
}
@keyframes blink-caret-animation {
    from, to { border-color: transparent }
    50% { border-color: white; }
  }

.media{
    display: -ms-flexbox;
    display: flex;
    padding: 10px 0;
}
.media p {
    margin-bottom: 0;
    padding-left: 15px;
    font-size: 14px;
    color: rgba(174,174,178,1);
}
.media label {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    -ms-flex: 0 0 90px;
    flex: 0 0 90px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    max-width: 90px;
}
.text-theme{
    background: linear-gradient(to right, #12c2e9, #f64f59, #f64f59);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.skill-lt .skill-bar {
    position: relative;
    background: rgba(255, 255, 255, 0.4);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px); 
}
.white-theme .skill-lt .skill-bar {
    background: rgba(41, 41, 41, 0.4); 
}
.skill-lt .skill-bar .skill-bar-in {
    width: 0px;
    transition: ease all 0.55s;
    height: 5px;
    position: relative; 
}
.skill-lt .skill-bar .skill-bar-in span {
    position: absolute;
    right: 0;
    top: -30px;
    font-size: 14px;
    color: #fff; 
}

.theme-bg-g {
    background: linear-gradient(to left, #12c2e9, #c471ed, #f64f59);
}

.dark-color{
    color: rgba(199, 199, 204, 1);
    font-size: 16px;
    margin: 0 0 10px;
}

.owl-dots {
    text-align: center;
}
.owl-dots button.owl-dot {
    display: inline-block;
    vertical-align: top;
    width: 10px;
    height: 10px;
    background: transparent;
    border: 1px solid #12c2e9;
    transition: ease all 0.55s;
    border-radius: 50%;
    margin: 0 5px; 
}
.owl-dots .owl-dot.active {
    background: #12c2e9; 
}
